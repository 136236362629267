<!--
    组件说明:
           propertys:    url(必须）下载地址
                         fileName(可选)缺省下载文件名
                         replaceFileName(可选)强制替换服务器返回的文件名
-->
<template>
      <div>
        <div class="head">
<!--          <el-button type="primary">下载<i class="el-icon-download el-icon&#45;&#45;right"></i></el-button>-->
          <div>
            <span>
              第{{imageIndex}}/{{imageParts}}部分
            </span>
            <el-button @click="modifyImageSize">修改图片大小</el-button>
            <slot></slot>
          </div>
        </div>
        <div class="imgholder" :style="{width: width+'px', height: height+'px'}">
          <img class="img" ref="image" :src="blobUrl"/>
          <el-button v-if="imageParts>0" @click="moveLeft" class="arrow left" icon="el-icon-back" circle></el-button>
          <el-button v-if="imageParts>0" @click="moveRight" class="arrow right" icon="el-icon-right" circle></el-button>
        </div>
<!--        <el-link v-if="fileName" href="#" :underline="false" style="margin-left: 10px;font-size: 15px;color:#409EFF;">下载</el-link>-->
      </div>
</template>

<script>
    export default {
        props:{
            url:{
                type: String,
                required: true
            },
            fileName: {
                type: String
            },
            //替换fileName和response返回的fileName
            replaceFileName:{
                type: String,
                default: ''
            },
            width: {
                type: Number,
                default: 600
            },
            height: {
                type: Number,
                default: 600
            }
        },
        data(){
            return{
              blobUrl: null,
              imageParts: 0,
              imageIndex: 0,
            }
        },
        methods:{
            moveLeft(){
              if(this.imageIndex>1){
                 this.imageIndex -=1;
              }
              const img = document.querySelector('.img')
              img.style.transform = `translateX(-${(this.imageIndex-1)*this.width}px)`
            },
            moveRight(){
              if(this.imageIndex < this.imageParts){
                this.imageIndex += 1;
              }
              const img = document.querySelector('.img')
              img.style.transform = `translateX(-${(this.imageIndex-1)*this.width}px)`
            },
            modifyImageSize(){
              this.$prompt('请输入新的大小,以*间隔,例300*300', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\d+[\\*]\d+$/,
                inputErrorMessage: '大小不符合规范'
              }).then(({ value }) => {
                let newWidth = parseInt(value.split('*')[0])
                let newHeight = parseInt(value.split('*')[1])
                if(newWidth > 1500 || newHeight > 1500){
                  this.$message.error("最大不能超过1500")
                  return false
                }
                if(newWidth > 0 && newHeight > 0){
                  this.$store.commit('setBarcodeWidth', newWidth);
                  this.$store.commit('setBarcodeHeight', newHeight);
                  this.$message({
                    type: 'success',
                    message: '修改成功, 请关闭重新打开图片对话框'
                  });
                }
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '取消输入'
                });
              });
            }
        },
        watch: {
            url: {
              immediate: true,
              handler(src) {
                if (!src) return
                // console.log(src)
                this.$axios.get(this.url, {
                  responseType: 'blob', // important
                  timeout: 20000,
                }).then((response) => {
                  // console.log(response)
                  //返回的是一个错误
                  if(response.headers['content-type']==='application/json'){
                    let reader = new FileReader();
                    reader.onload = e => this.$message.error(JSON.parse(e.target.result).msg);
                    reader.readAsText(response.data);
                    return
                  }
                  //显示图片
                  this.blobUrl = window.URL.createObjectURL(response.data);
                  const img = document.querySelector('.img')
                  img.onload = () => {
                    this.imageParts = img.width/this.width;
                    this.imageIndex = 1;
                  };
                  //显示下载按钮
                  let fileName = this.fileName;
                  if(!fileName) return
                  if(response.headers["content-disposition"]){
                      const patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
                      const result = patt.exec(response.headers["content-disposition"]);
                      if(result.length>1){
                          fileName = decodeURI(result[1]);
                      }
                  }
                  if(this.replaceFileName){
                      fileName = this.replaceFileName
                  }
                  const url = window.URL.createObjectURL(new Blob([response.data],
                      { type: 'application/octet-stream' }));
                  const link = document.querySelector('.el-link');
                  if(link){
                      link.href = url;
                      link.setAttribute('download', fileName);
                  }
                });
              },
            },
          },
    }
</script>

<style scoped>
  .imgholder{
    overflow: hidden;
  }
  .imgholder .img{
    position: relative
  }
  .arrow{
    position: fixed;
    scale: 200%;
    /*background-color: transparent;*/
    top: 50%;
    transform: translateY(-50%);
  }
  .left{
    left: calc(15vw - 65px);
  }
  .right{
    right: calc(15vw - 65px);
  }
  .head{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .head span{
    font-size: 20px;
    font-weight: bolder;
  }
</style>